import * as React from 'react';
import { PageProps, graphql } from 'gatsby';
import { HomeIndexQuery } from '../../graphql-types';
import Seo from '../components/seo';
import Events from '../components/events';
import Subscribe from '../components/subscribe';
import About from '../components/about';
import PostList from '../components/post-list';
import News from '../components/news';

const HomeIndex = ({ data }: PageProps<HomeIndexQuery>) => {
  return (
    <>
      <Seo title="Home" />
      <Events />
      <News />
      <Subscribe />
      <About />
    </>
  );
};

export default HomeIndex;

export const pageQuery = graphql`
  query HomeIndex {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
