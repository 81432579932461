import React, { useState } from 'react';
import { MailIcon } from '@heroicons/react/outline';

const Subscribe = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  let handleSubmit = async e => {
    e.preventDefault();
    try {
      let res = await fetch('https://nobsrfc-api.netlify.app/api/subscribe', {
        method: 'POST',
        body: JSON.stringify({
          firstName: firstName,
          lastName: lastName,
          email: email,
        }),
      });
      let resJson = await res.json();
      // Test
      console.log(resJson);

      if (res.status === 200) {
        setFirstName('');
        setLastName('');
        setEmail('');
        setMessage(resJson);
      } else {
        setMessage('Some error happened');
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <section id="subscribe" className="lg:container bg-c1 dark:bg-c1d">
      <div className="container grid gap-4 lg:grid-cols-2 items-center justify-center ">
        <div className="grid gap-3 justify-center items-center text-center">
          <h2 className="dark:text-ct2d ">Subscribe to our emails and newsletters</h2>
          <p className="text-inherit">Receive news and update</p>
          <MailIcon className="h-16 mx-auto" />
        </div>
        <form onSubmit={handleSubmit} className="grid gap-3">
          <input value={email} type="email" placeholder="Email" onChange={e => setEmail(e.target.value)} />
          <input
            value={firstName}
            type="text"
            placeholder="First Name"
            onChange={e => setFirstName(e.target.value)}
          />
          <input
            value={lastName}
            type="text"
            placeholder="Last Name"
            onChange={e => setLastName(e.target.value)}
          />
          <input value="Subscribe" type="submit" className="btn-secondary" />
          <div className="message">{message ? <p>{message}</p> : null}</div>
        </form>
      </div>
    </section>
  );
};

export default Subscribe;
