import React from 'react';
import PostList from './post-list';

const News = () => {
  return (
    <section id="news" className="container bg-cg2 dark:bg-cg9d">
      <h2 className="h-bar mb-6">Latest News</h2>
      <PostList />
    </section>
  );
};

export default News;
