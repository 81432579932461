import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PostCard from './post-card';

const PostList = () => {
  const data = useStaticQuery(graphql`
    query PostList {
      allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
        nodes {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            featuredImage {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
        }
      }
    }
  `);

  const posts = data.allMarkdownRemark.nodes;

  return (
    <ol className="grid gap-4 items-stretch lg:grid-cols-2">
      {posts.map((post: any) => {
        const title = post.frontmatter.title || post.fields.slug;
        return (
          <li key={post.fields.slug} className="items-stretch">
            <PostCard
              featuredImage={post.frontmatter.featuredImage}
              title={title}
              slug={post.fields.slug}
              date={post.frontmatter.date}
              excerpt={post.excerpt}
              description={post.frontmatter.description}
            />
          </li>
        );
      })}
    </ol>
  );
};

export default PostList;
