import React from 'react';
import BgImage from './bg-image';
import { CalendarIcon } from '@heroicons/react/outline';

const calendarId = 'admin@nobsrfc.com';
const calendarLink = `https://calendar.google.com/calendar/embed?src=${calendarId}&ctz=America%2FToronto`;

interface calEvent {
  summary: string;
  location: string;
  date: string;
  time: string;
}

const Events = () => {
  const [events, setEvents] = React.useState<Array<calEvent>>([]);
  const getEvents = () => {
    fetch('https://nobsrfc-api.netlify.app/api/events')
      .then(res => res.json())
      .then(data => setEvents(data));
  };
  React.useEffect(() => {
    getEvents();
  }, []);
  return (
    <section id="events" className="lg:container lg:p-0 bg-c3 dark:bg-c3d text-ct1">
      <BgImage>
        <div className="container grid gap-6 lg:grid-cols-2 place-items-center">
          <div className="grid gap-2 justify-center items-center text-center">
            <h2 className="">Upcoming Events</h2>
            <p className="text-white">Check here frequently for updates</p>
            <CalendarIcon className="hidden lg:block lg:h-32 mx-auto text-cg4 dark:text-c1d" />
          </div>
          <div className="grid gap-4 place-items-center mt-4">
            <div className="bg-cg9 opacity-50 w-full h-full row-start-1 col-start-1"></div>
            <div className="row-start-1 col-start-1 p-4 z-10 place-items-center">
              {events.map((event, i) => (
                <div className="grid justify-center items-center text-center mb-4" key={i}>
                  <div
                    className={`${
                      i === 0 ? 'bg-c1 text-ct2 dark:bg-c1d dark:text-ct2d' : 'bg-cg3 text-ct2'
                    } p-1 text-lg min-w-[240px] mx-auto`}
                  >
                    {event.date}
                  </div>
                  <div className="bold mt-2">{event.summary}</div>
                  <div className="font-thin">{`${event.time} (${event.location})`}</div>
                </div>
              ))}
              <a className="btn-outline mx-auto" href={calendarLink}>
                See Full Calendar
              </a>
            </div>
          </div>
        </div>
      </BgImage>
    </section>
  );
};

export default Events;
