import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

const BgImage = (props: any) => {
  return (
    <div className="grid text-white">
      <div className="row-start-1 col-start-1 dark:grayscale">
        {' '}
        <StaticImage
          className="h-full"
          imgClassName=""
          src="../images/cover-nobs-lg.jpg"
          objectFit="cover"
          alt="background image"
          placeholder="none"
        />
      </div>
      <div className="row-start-1 col-start-1 z-10 w-full h-full bg-black opacity-60"></div>
      <div className="row-start-1 col-start-1 z-20 ">{props.children}</div>
    </div>
  );
};

export default BgImage;
