import React from 'react';

const About = () => {
  return (
    <section id="about">
      <div className="container bg-cg1 dark:bg-cg9d text-ct2 dark:text-ct1d grid gap-8 lg:grid-cols-2 items-stretch">
        <div>
          <div className="flex-1">
            <h2 className="h-bar">About Us</h2>
            <p className="mt-4  dark:text-ct3d mx-4">
              We are an over 35 rugby club located in Burlington, Ontario. Our home field and clubhouse are
              located in Sherwood Forest Park at the end of Fairview Street on the east side of Appleby Line.{' '}
            </p>
            <p className="mt-4 dark:text-ct3d mx-4">
              We are always looking for enthusiastic players so please come join us for a game or a pint. We
              play touch on Tuesday and Thursday nights and full contact games are usually on Sundays at
              1:00pm.
            </p>
          </div>
          <div className="mt-6">
            <h2 className="h-bar">Field & Clubhouse</h2>
            <div className="font-black mt-4 mx-4">Sherwood Forest Park </div>
            <p className="dark:text-ct3d mx-4">5270 Fairview Street </p>
            <p className="dark:text-ct3d mx-4">Burlington, Ontario</p>
          </div>
        </div>
        <div className="flex-1">
          <h2 className="h-bar">Directions</h2>
          <div className="h-full w-full pt-4 pb-8 ">
            <div className="h-96 lg:h-full bg-cg3">Map</div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
