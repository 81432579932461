import React from 'react';
import { Link } from 'gatsby';
import { PostProps } from '../types/types';
import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image';

const PostCard = (props: PostProps) => {
  const image = getImage(props.featuredImage);
  return (
    <Link to={props.slug} className="hover:no-underline" itemProp="url">
      <article className=" h-full shadow-xl border dark:border-0 p-4 rounded sm:flex sm:gap-4 bg-white dark:bg-cg8d ">
        <section className="">
          {image != null ? (
            <GatsbyImage
              className="dark:grayscale shadow-lg w-full aspect-[2/1] sm:w-[240px] sm:h-full border"
              image={image}
              alt="GatsbyImage"
            />
          ) : (
            <StaticImage
              src="../images/me.jpg"
              layout="fixed"
              formats={['auto', 'webp', 'avif']}
              width={200}
              height={200}
              quality={95}
              alt="StaticImage"
            />
          )}
        </section>
        <section>
          <header className="mt-4 sm:mt-0">
            <h3 className="text-c2 dark:text-c1d ">
              <span itemProp="headline">{props.title}</span>
            </h3>
            <div className="text-sm font-sans dark:text-cg4d">{props.date}</div>
          </header>
          <p
            className="mt-2 font-light leading-6 text-cg5 dark:text-cg2d"
            dangerouslySetInnerHTML={{
              __html: props.description || props.excerpt || '',
            }}
          />
        </section>
      </article>
    </Link>
  );
};

export default PostCard;
